.wrapper {
  background: white;

  flex: 0 1 calc((100% / 3) - 2px);
  aspect-ratio: 16 / 9;

  position: relative;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;

  @media (--tabletMin) {
    max-width: calc(50% - 2px);
  }

  @media (--tabletMax) {
    flex: 1 1 320px;
  }

  @media (--mobile) {
    flex: 1 1 200px;
  }

  &[data-variant='alt'] {
    border-radius: 3px;
    flex: 0 1 calc((100% / 3) - 14px);
    place-items: center;
    place-content: center;

    @media (--tabletMax) {
      flex: 1 1 320px;
    }
  }

  @media (--tablet) {
    flex: 0 1 calc((100% / (var(--itemsPerRow) - 2)) - (2px));

    & .title {
      font-size: calc(19px - (1px * (var(--itemsPerRow) - 2)));
      padding: 5px 15px;
    }
  }


  @media (--desktop) {
    flex: 0 1 calc((100% / var(--itemsPerRow)) - (2px));

    & .title {
      font-size: calc(19px - (1px * var(--itemsPerRow)));
      padding: 5px 10px;
    }
  }
}

.images {
  position: absolute;
  background: #888;
  width: 100%;
  height: 100%;

  & .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* filter: brightness(0.9); */
    opacity: 0;
    transition: 1s;
    /* transition-delay: 1s; */
    pointer-events: none;

    @nest .wrapper[data-variant='alt'] & {
      /* filter: brightness(0.7); */
    }

    &[data-is-active='true'] {
      transition: 1s;
      opacity: 1;
      pointer-events: all;
    }

    & img {
      object-fit: cover;
      object-position: center;
    }
  }
}

.title {
  position: absolute;
  left: 0;
  bottom: 30px;
  font-size: 19px;
  line-height: 24px;
  font-family: 'Oswald', Arial, sans-serif;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-transform: uppercase;
  padding: 10px 15px;
  max-width: 265px;
  z-index: 5;
  /* min-height: 45px; */
  transition: 0.3s linear;
  font-weight: 430;

  @media (--mobile) {
    font-size: 13px;
    line-height: 1.1;
    padding: 0.8vh 1.6vh;

    bottom: 10px;
    max-width: none;
  }

  @nest .wrapper:hover & {
    background: var(--primary);
    pointer-events: all;
    transform: translateX(0);
  }

  @nest .wrapper[data-variant='alt'] & {
    position: relative;
    /* background-color: rgba(0, 0, 0, 0); */
    max-width: none;
    min-height: none;
    padding: 0;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    place-items: center;
    place-content: center;
    font-size: 24px;
    font-weight: 400;
    /* background: white; */
    /* color: white; */
    padding: 10px 20px;
    text-decoration: none;
    /* color: black; */
    transition: 0.2s;
    text-align: center;
  }

  @nest .wrapper[data-variant='alt']:hover & {
    background: var(--primary);
    color: white;
  }

  @nest .wrapper[data-variant='alt']:active & {
    background: var(--secondary);
    color: white;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  transition: 0.3s ease-out;
  pointer-events: none;
  transform: translateX(-100%);

  @nest .wrapper:hover & {
    opacity: 1;
    transform: translateX(0);
  }

  @nest .wrapper[data-variant='alt'] & {
    /* opacity: 0; */
  }
}
