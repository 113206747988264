.wrapper {
  padding-top: 60px;
  margin-bottom: 50px;
  height: 320px;
  width: 100%;
  display: flex;
  place-content: center;
  place-items: center;
  text-align: center;

  background: black;
  color: white;
  background-position: center;
  background-size: cover;
  position: relative;

  @media (--mobile) {
    height: 220px;
    margin-bottom: 20px;
    padding-top: 60px;
    padding-bottom: 20px;
    /* align-items:flex-start; */
  }

  &[data-variant='noBanner'] {
    background: unset;
    color: unset;
    text-align: left;
    margin: 60px 0 0;
    height: auto;
    padding: 50px 0 30px;

    @media (--mobile) {
      padding: 30px 0;
    }

    & [class='container'] {
      width: 100%;
    }

    & .title {
      text-shadow: none;
    }

    & .images {
      display: none;
    }
  }
}

.title {
  font-size: 50px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  position: relative;
  display: flex;
  flex-direction: column;
  text-shadow: 0 0px 2px rgb(0 0 0 / 50%);

  @media (--mobile) {
    font-size: 8vw;
  }
}

.subtitle {
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
  line-height: 1;

  @media (--mobile) {
    font-size: 3vw;
    margin-top: 2vw;
  }
}

.images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(0.7);
  opacity: 0;
  transition: 4s linear;
  pointer-events: none;

  & img {
    object-fit: cover;
    object-position: center;
  }

  &[data-is-active='true'] {
    opacity: 1;
    pointer-events: all;
  }
}
